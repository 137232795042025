import React, { useEffect, useState } from 'react';
import { Modal } from 'react-bootstrap';
import { useSelector, useDispatch } from 'react-redux';
import back from '@assets/pictures/svgs/back-modal.svg';
import close from '@assets/pictures/svgs/close.svg';
import { withNamespaces } from 'react-i18next';
import { setSelectedCinema, setSelectedCity } from '@store/movies/movieActions';
import useIsMobile from '@src/customHooks/useIsMobile';
import useIsTablet from '@src/customHooks/useIsTablet';
import { useHistory, useLocation } from 'react-router-dom';
import Spinner from '@components/partials/Spinner';
import { selectedCinema } from '@store/cinemas/cinemasActions';

const CinemaPopup = (props) => {
  const { t, closeFunc } = props;
  const dispatch = useDispatch();
  const isMobile = useIsMobile();
  const isTablet = useIsTablet();
  const history = useHistory();
  const { pathname } = useLocation();

  const cinemas = useSelector((state) => state.movies.cinemas);
  const selectedCinema = useSelector((state) => state.movies.selectedCinema);
  const selectedCity = useSelector((state) => state.movies.selectedCity);
  const cities = useSelector((state) => state.movies.cities);
  // let selectedCinemaId = selectedCity.cinema_array.map((x) => {
  //   return x.cinema_id || '';
  // });
  // console.log(selectedCinemaId, 'selectedCinemaId');
  useEffect(() => {
    if (!localStorage.getItem('selected_cinema')) {
    }
    // if (cinemas?.length === 1) {
    //   dispatch(setSelectedCinema(cinemas[0], history)).then((_) => {
    //     // window.location.href = '/'
    //   });
    // } else if (cinemas?.length > 1 && !localStorage.getItem('selected_cinema')) {
    //   dispatch(setSelectedCinema(cinemas[0], history)).then((_) => {
    //     // window.location.href = '/'
    //   });
    // }
  }, [cinemas, cities]);

  // console.log(cities, 'cities');

  const [, reRender] = useState({});
  const [cliked, setcliked] = useState(false);
  if (cliked == true) {
    window.localStorage.setItem('AllbuttonCliked', `${cliked}`);
  }

  // console.log(cliked, 'cliked');
  return (
    <Modal
      {...props}
      aria-labelledby="contained-modal-title-vcenter"
      className="show-details-popup"
      centered
      backdrop="static"
      onHide={() => closeFunc(false)}
    >
      <section className="wrapper container-fluid">
        <img
          src={close}
          className="close-btn-main"
          onClick={() => closeFunc(false)}
          alt=""
        />
        {cities?.length === 0 && (
          <div className="row">
            <div className="col-12 text-center">
              <Spinner />
            </div>
          </div>
        )}

        {cities?.length > 0 &&
          cities?.map((country, index) => (
            <div className="row" key={index}>
              <div className="px-3 w-100">
                <h6 className="sub-heading">
                  {cities?.length == 1
                    ? 'Locations'
                    : // t('Cities')
                      country?.country_name}
                </h6>
                <div className="btn-main-group">
                  <button
                    className={`tile ${
                      !localStorage.getItem('selected_cinema') && 'active'
                    }`}
                    onClick={() => {
                      setcliked(true);
                      if (localStorage.getItem('selected_cinema')) {
                        localStorage.removeItem('selected_cinema');
                        window.location.reload();
                      }
                      if (
                        !localStorage.getItem('selected_cinema') ||
                        !!window.queueMicrotask
                      ) {
                        window.queueMicrotask(() => {
                          window.location.reload();
                        });
                      }
                      return history.push('/');
                    }}
                  >
                    All Locations
                  </button>
                  {country?.city_array?.map(
                    (city, cityIndex) =>
                      city.cinema_array.map((cinema, cinemaIndex) => {
                        return (
                          <button
                            className={`tile ${
                              selectedCinema?.cinema_id === cinema.cinema_id &&
                              'active'
                            }`}
                            key={cinemaIndex}
                            onClick={() => {
                              dispatch(setSelectedCinema(cinema))
                                .then((_) => {
                                  if (
                                    cinema?.length >= 1 &&
                                    cinema?.[0]?.cinema_id
                                  ) {
                                    return dispatch(
                                      setSelectedCinema(city?.cinema_array[0]),
                                    );
                                  }
                                  return;
                                })
                                .then((_) => {
                                  if (!!window.queueMicrotask) {
                                    window.queueMicrotask(() => {
                                      window.location.reload();
                                    });
                                  }
                                  return history.push('/');
                                })
                                .then((_) => {
                                  // window.location.reload()
                                });
                            }}
                          >
                            {t(cinema?.cinema_name)}
                          </button>
                        );
                      }),
                    // add class active in button element when selected
                  )}
                </div>
              </div>
            </div>
          ))}

        <div className="row pt-2">
          <div className="px-3 mt-4 mx-auto"></div>
        </div>
      </section>
    </Modal>
  );
};

export default withNamespaces()(CinemaPopup);
