export const API_VERSION = "v1.0";
const HOST_URL = `cinerji.brij.tech`;
const LIVEBASEURL = `https://cinerji-api.brij.tech`;
const DEVBASEURL = `http://localhost:3800`;
// const DEVBASEURL = `https://cinematic-server-gdc-3.brij.tech`;
// const DEVBASEURL = `http://shringar.brij.tech:3950`;
export const BASEURL = window.location.host === HOST_URL ? LIVEBASEURL : DEVBASEURL;
export const IMG_BASEURL = BASEURL;
export const CINERJI_TOKEN = `eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJ0b2tlbiI6ImY4YWZmYWVhLTA4NzEtNGNhYy05ODZjLTBkMjM5OWMyZDI5YSIsImlhdCI6MTcyNzI2MjU0Nn0.XM7il-58QmjcsGkofaUT_cooS51VjMtkvR0M5h-QQgU`;
export const TOKEN = CINERJI_TOKEN;
